<template>
    <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div v-if="homeworks.length == 0"><h3>Không có bài tập về nhà</h3></div>
        <div v-if="homeworks.length > 0" class="panel-container show">
            <p v-if="typeView == 'view-result'" class="title-header">Xem kết quả bài tập về nhà</p>
            <p v-else class="title-header">Nội dung bài tập về nhà buổi {{lesson}}</p>
            <hr>
            <div v-if="typeView == 'view-result'" class="head-homework">
                <div class="row">
                    <div class="col-md-6 col-infor">
                        <div class="d-flex">
                            <span class="title-one-infor">Học viên: </span>
                            <b>{{ mark_detail.name }}</b>
                        </div>
                        <div class="d-flex">
                            <span class="title-one-infor">Số điện thoại:</span>
                            <b>{{ mark_detail.phone }}</b>
                        </div>
                        <div class="d-flex">
                            <span class="title-one-infor">Buổi học:</span>
                            <b>{{ lesson }}</b>
                        </div>
                    </div>
                    <div class="col-md-6 col-infor">
                        <div class="d-flex">
                            <span class="title-two-infor">Số câu đúng bài trắc nghiệm:</span>
                            <b>{{ mark_detail.test.score_auto_mark }}/{{ mark_detail.test.num_auto_mark }}</b>
                        </div>
                        <div class="d-flex">
                            <span class="title-two-infor">Số câu tự luận:</span>
                            <b>{{ esay_length }}</b>
                        </div>
                        <div class="d-flex">
                            <span class="title-two-infor">Thời gian nộp bài:</span>
                            <b>{{mark_detail.test.created_at | formatDateTimeVietnam}}</b>
                        </div>
                    </div>
                </div>
            </div>
          <div class="panel-content">
            <el-row v-loading="isloadingHomework" v-for="(questions, index) in homeworks" :key="questions.id">
              <single-choice :question="questions"  v-if="questions.type === 'single_choice'" :type-view="typeView"></single-choice>
              <multi-choice :question="questions"  v-if="questions.type === 'multiple_choice'" :type-view="typeView"></multi-choice>
              <fill-text-audio :question="questions"  v-if="questions.type === 'fill_text_audio' || questions.type === 'fill_text_script'" :type-view="typeView"></fill-text-audio>
              <write-paragraph :question="questions" v-if="questions.type === 'write_paragraph'&& !isTestKid" :type-view="typeView"></write-paragraph>
              <write-paragraph-kid :question="questions" v-if="questions.type === 'write_paragraph' && isTestKid" :type-view="typeView"></write-paragraph-kid>
              <record-audio :question="questions" v-if="questions.type === 'record_audio'&& !isTestKid" :type-view="typeView"></record-audio>
              <record-audio-kid :question="questions" v-if="questions.type === 'record_audio' && isTestKid" :type-view="typeView"></record-audio-kid>
              <match-text-image :question="questions" v-if="questions.type === 'match_text_image'" :type-view="typeView"></match-text-image>
              <arrange-sentences :question="questions" v-if="questions.type === 'arrange_sentences'" :type-view="typeView"></arrange-sentences>
              <record-video :question="questions" v-if="questions.type === 'record_video'&& !isTestKid" :type-view="typeView"></record-video>
              <record-video-kid :question="questions" v-if="questions.type === 'record_video'&& isTestKid" :type-view="typeView"></record-video-kid>
            </el-row>
            <div class="edutalk-paging">
            <div class="block">
                <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1"
                            :click-handler="clickCallback" :prev-text="'Trước'" :next-text="'Sau'"
                            :container-class="'pagination b-pagination justify-content-center'" :pageLinkClass="'page-link'"
                            :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'"
                            :prev-class="'page-link'" :next-class="'page-link'" :page-class="'page-item'">
                        </paginate>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CONTENT_HOMEWORK, RESULT_HOMEWORK, ADMIN_HOMEWORK } from "@/core/services/store/course/classes.module";
import singleChoice from "./components/homework-type/single-choice.vue";
import fillTextAudio from "./components/homework-type/fill-text-audio";
import writeParagraph from "./components/homework-type/write-paragraph";
import recordAudio from "./components/homework-type/record-audio";
import matchTextImage from "./components/homework-type/match-text-image";
import arrangeSentences from "./components/homework-type/arrange-sentences";
import RecordVideo from "@/view/pages/classes/components/homework-type/record-video";
import MultiChoice from "@/view/pages/classes/components/homework-type/multi-choice";
import {Cambridge_Mentor_ID} from "@/core/option/typeCenterBill";
import WriteParagraphKid from "@/view/pages/classes/components/homework-type/write-paragraph-kid.vue";
import RecordAudioKid from "@/view/pages/classes/components/homework-type/record-audio-kid.vue";
import RecordVideoKid from "@/view/pages/classes/components/homework-type/record-video-kid.vue";


export default {
    name: "ClassDetail",
    components: {
      RecordVideoKid,
      RecordAudioKid,
      WriteParagraphKid,
      RecordVideo,
        singleChoice,
        fillTextAudio,
        writeParagraph,
        recordAudio,
        matchTextImage,
        arrangeSentences,
        MultiChoice
    },
    data() {
        return {
            isFilter: false,
            homeworks: [],
            last_page: 1,
            page: 1,
            classroom_id: null,
            learning_schedule_id : null,
            query: null,
            typeView:'view-homework',
            isloadingHomework : true,
            student_id : null,
            lesson : 0,
            mark_detail : null,
            esay_length : 0,
        }
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quản lý lớp học", route: 'schedule-class',  id: this.$route.query.classroom_id },
            { title: "Nội dung bài tập về nhà" }
        ]);
        let view_result = this.$route.query.type;
        this.lesson =this.$route.params.id;
        if (view_result) {
            this.typeView = "view-result";
            this.lesson = this.$route.query.lesson;
        }
        this.classroom_id = this.$route.query.classroom_id;
        this.student_id = this.$route.query.student_id;
        this.learning_schedule_id = this.$route.query.learning_schedule_id;
        this.getHomeWork();
    },
    computed: {},
    methods: {
        getHomeWork() {
            switch (this.typeView) {
                case 'view-homework':
                this.getContentHomeWork();
                break;
                case 'view-result':
                this.getMarkStudent();
                this.getResultHomeWork();
                break;
                default:
            }
        },
        getContentHomeWork(){
            this.isloadingHomework = true;
            this.query = {
                classroom_id:this.classroom_id,
                learning_schedule_id:this.learning_schedule_id,
                 center_id: this.$route.query.center_id
            }
            let payload = {...this.query, page: this.page}
            this.$store.dispatch(CONTENT_HOMEWORK,
            payload).then((res) => {
                if (!res.error) {
                    this.homeworks = res.data.data;
                    this.last_page = res.data.last_page;
                    this.isloadingHomework = false;
                  console.log(this.homeworks);
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getResultHomeWork(){
            this.isloadingHomework = true;
            this.query = {
                classroom_id:this.classroom_id,
                learning_schedule_id:this.learning_schedule_id,
                student_id : this.student_id,
                lesson : this.lesson,
                type:'homework',
              center_id: this.$route.query.center_id
            }
            let payload = {...this.query, page: this.page}
            this.$store.dispatch(RESULT_HOMEWORK,
            payload).then((res) => {
                if (!res.error) {
                    this.esay_length = res.data.count_esay;
                    this.homeworks = res.data.test.data;
                    this.last_page = res.data.test.last_page;
                    this.isloadingHomework = false;
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getMarkStudent(){
            this.isloadingHomework = true;
            this.$store.dispatch(ADMIN_HOMEWORK, {classroom_id:this.classroom_id,lesson:this.lesson,type:'homework',user_id:this.student_id}).then((res) => {
                if (!res.error) {
                    this.mark_detail = res.data[0];
                    this.loadingMarkHomework = false;
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        clickCallback(obj) {
            this.page = obj
            this.$router.push({
                name: 'content-homework',
                query: {
                    page: this.page,
                    ...this.query
                }
            })
            switch (this.typeView) {
                case 'view-homework':
                this.getContentHomeWork();
                break;
                case 'view-result':
                this.getResultHomeWork();
                break;
                default:
            }
        },
      isTestKid(){
        return +this.$route.query.center_id === Cambridge_Mentor_ID
      }
    }
}
</script>

<style lang="scss" scoped>
.head-homework {
    width: 100%;
    height: auto;
    background: #FBF5FF;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 14px;
}
.col-infor:first-child {
    margin-bottom: 20px;
}

@media (min-width: 430px) {
.col-infor:first-child {
    margin-bottom: 0;
}
.col-infor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100px;
}
.title-one-infor {
    display: block;
    width: 100px;
}
.title-two-infor {
    display: block;
    width: 200px;
}  
.title-header {
  font-weight: 700;
  font-size: 20px;
  color: #5A5A5A;
  padding-top: 20px;
} 
    
}
</style>